import { Button } from '@components/button';
import { Divider } from '@components/divider';
import { Flex } from '@components/grid';
import { OrderItemList } from '@components/order-item-list';
import { P } from '@components/typography';
import { fontWeight } from '@constants/font-weight';
import { useFeature } from '@hooks/use-feature';
import { colors } from '@nandosaus/constants';
import { useRootStore } from '@nandosaus/state-management';
import { sortByDefaultFirst, sortByName } from '@nandosaus/state-management/lib/models/sub-cart';
import { orderItemPropTypes } from '@prop-types/order-item';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const SUB_CART_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  orderItems: PropTypes.arrayOf(orderItemPropTypes),
});

const SubCartHeading = ({
  name,
  removeItemCount,
  showRemoveButton,
  onClickRemove,
  removeButtonDisabled,
  showUndoButton,
  onClickUndo,
}) => {
  const cartRemovalEnabled = useFeature('remove-from-cart');

  return (
    <>
      <Divider my="1rem" />
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <P role="heading" aria-level="3" textTransform="uppercase" variant={4} fontWeight={fontWeight.bold}>
          {name}
        </P>

        {showRemoveButton && cartRemovalEnabled && (
          <Button
            labelProps={{ textTransform: 'none', fontWeight: fontWeight.normal }}
            py="0px !important"
            variant="destructiveLink"
            disabled={removeButtonDisabled}
            onClick={onClickRemove}
          >
            Remove {removeItemCount} items
          </Button>
        )}

        {showUndoButton && cartRemovalEnabled && (
          // !important padding is unfortunately required due to being later overridden by the link variant
          <Flex flexDirection="row">
            <P variant={4} p="8px" backgroundColor={colors.grey100} fontWeight={fontWeight.medium} borderRadius="8px">
              Removed
            </P>
            <Button
              labelProps={{ textTransform: 'none', fontWeight: fontWeight.normal }}
              py="0px !important"
              ml="8px"
              variant="link"
              onClick={onClickUndo}
            >
              Undo
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  );
};

SubCartHeading.propTypes = {
  name: PropTypes.string.isRequired,
  removeItemCount: PropTypes.number,

  showRemoveButton: PropTypes.bool.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  removeButtonDisabled: PropTypes.bool.isRequired,

  showUndoButton: PropTypes.bool.isRequired,
  onClickUndo: PropTypes.func.isRequired,
};

SubCartHeading.defaultProps = {
  removeItemCount: undefined,
};

const SubCartItemList = ({ subCart, isRemoved, onClickRemove, onClickUndo, disableOnClick, isRemovalDisabled }) => {
  const { orderItems, name } = subCart;

  return (
    <>
      <SubCartHeading
        name={name}
        removeItemCount={orderItems.length}
        showRemoveButton={orderItems.length > 1 && !disableOnClick}
        showUndoButton={isRemoved}
        onClickRemove={onClickRemove}
        onClickUndo={onClickUndo}
        removeButtonDisabled={isRemovalDisabled}
      />
      {orderItems?.length > 0 || (
        <P variant={4} color={colors.grey500} fontWeight={400}>
          No items
        </P>
      )}
      {!isRemoved && <OrderItemList orderItems={orderItems} disableOnClick={disableOnClick} />}
    </>
  );
};

SubCartItemList.propTypes = {
  subCart: SUB_CART_PROP_TYPE.isRequired,
  isRemoved: PropTypes.bool.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  onClickUndo: PropTypes.func.isRequired,
  disableOnClick: PropTypes.bool.isRequired,
  isRemovalDisabled: PropTypes.bool.isRequired,
};

const GroupedItemList = ({ subCarts, disableOnClick, showHeader }) => {
  const { CartStore } = useRootStore();

  const allSubCartsByName = useMemo(() => {
    const removedSubCarts = CartStore.removedSubCartIds || [];

    const allSubCarts = [...subCarts, ...removedSubCarts];

    allSubCarts.sort(sortByName);
    allSubCarts.sort(sortByDefaultFirst);
    return allSubCarts;
  }, [CartStore.removedSubCartIds, subCarts]);

  return (
    <>
      {showHeader && (
        <P role="heading" aria-level="3" variant={3} color={colors.grey500} fontWeight={400}>
          Your items
        </P>
      )}

      {allSubCartsByName.map(subCart => {
        const isRemoved = subCart.orderItems === undefined;

        return (
          <SubCartItemList
            key={subCart.id}
            subCart={isRemoved ? { ...subCart, orderItems: [] } : subCart}
            disableOnClick={disableOnClick}
            onClickRemove={() => CartStore.removeSubCart(subCart.id)}
            onClickUndo={() => CartStore.undoRemoveSubCart(subCart.id)}
            isRemovalDisabled={CartStore.loading}
            isRemoved={isRemoved}
          />
        );
      })}
    </>
  );
};

GroupedItemList.propTypes = {
  subCarts: PropTypes.arrayOf(SUB_CART_PROP_TYPE).isRequired,
  disableOnClick: PropTypes.bool,
  showHeader: PropTypes.bool,
};

GroupedItemList.defaultProps = { disableOnClick: false, showHeader: true };

const ObservedGroupedItemList = observer(GroupedItemList);

export { ObservedGroupedItemList as GroupedItemList };
