import { Alert } from '../alert';
import { Col, Container, Row } from '../grid';

const HolidaySurchargeAlert = () => {
  return (
    <Container>
      <Row>
        <Col width={{ _: 12 / 12, lg: 10 / 12 }} offset={[0, 0, 0, 1 / 12]}>
          <Alert my={{ _: 1, lg: 2 }} allowDismissal heading="15% surcharge applies on Public Holidays" type="info">
            We love a good celebration and so do our team. That’s why we’ve introduced a 15% surcharge on Public
            Holidays, allowing us to stay open and keep the good times cookin’.
            <br />
            <br />
            For our members, today (and future public holidays) you’ll be unable to pay with points, and you’ll only
            earn points on standard menu items (not including 15% surcharge).
            <br />
            <br />
            Thanks for understanding.
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

HolidaySurchargeAlert.propTypes = {};

export { HolidaySurchargeAlert };
