import { Flex } from '@components/grid';
import { QuantityPicker } from '@components/quantity-picker';
import { P, Subheading } from '@components/typography';
import { colors, RECOMMENDATION_ENGINE } from '@nandosaus/constants';
import { useField } from 'formik';
import { forwardRef } from 'react';
import { ErrorMessage, GreenHeading, InactiveError, Section } from '../../ui';

const { maxGuests } = RECOMMENDATION_ENGINE;

export const SelectGroup = forwardRef((props, ref) => {
  const validateAdults = adults => (adults === 0 ? 'Add one or more adults to continue' : undefined);

  const [adultsField, adultsMeta, adultsHelper] = useField({ name: 'group.adults', validate: validateAdults });
  // eslint-disable-next-line no-unused-vars
  const [kidsField, kidsMeta, kidsHelper] = useField({ name: 'group.kids' });
  const { error, touched } = adultsMeta;

  return (
    <Section borderColor={colors.grey100} ref={ref}>
      <Flex>
        <GreenHeading>How many guests?</GreenHeading>
      </Flex>
      <P>This’ll give us an idea of how much food to give you. Obviously.</P>
      {error && touched ? <ErrorMessage>{error}</ErrorMessage> : <InactiveError>Required</InactiveError>}
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <P>Adults</P>
        <QuantityPicker
          quantity={adultsField.value}
          increment={() => {
            adultsHelper.setTouched(true, false);
            adultsHelper.setValue(adultsField.value + 1);
          }}
          decrement={() => {
            adultsHelper.setTouched(true, false);
            adultsHelper.setValue(adultsField.value - 1);
          }}
          max={maxGuests - kidsField.value}
          min={0}
          hideWhenDisabled="min"
        />
      </Flex>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <P>Children</P>
        <QuantityPicker
          quantity={kidsField.value}
          increment={() => {
            kidsHelper.setTouched(true, false);
            kidsHelper.setValue(kidsField.value + 1);
          }}
          decrement={() => {
            kidsHelper.setTouched(true, false);
            kidsHelper.setValue(kidsField.value - 1);
          }}
          max={maxGuests - adultsField.value}
          min={0}
          hideWhenDisabled="min"
        />
      </Flex>
      <InactiveError>Number of kids will indicate how many Nandino (Kids’ meals) we recommend</InactiveError>
      <Flex justifyContent="flex-end">
        <Subheading variant={2}>Total: {adultsField.value + kidsField.value}</Subheading>
      </Flex>
    </Section>
  );
});
