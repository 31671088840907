import { ALCOHOL_DISCLAIMER } from '@nandosaus/constants';

import { Col, Container, Row } from '../grid';
import { P } from '../typography';
import { useFeature } from '../../hooks/use-feature';

const MenuDisclaimer = () => {
  const alcoholPurchaseEnabled = useFeature('purchase-alcohol');

  if (!alcoholPurchaseEnabled) {
    return null;
  }

  return (
    <Container pb={3}>
      <Row>
        <Col width={{ xs: 12 / 12, lg: 10 / 12 }} offset={[0, 0, 0, 1 / 12]}>
          <P variant={5} textAlign="center" color="grey600">
            {ALCOHOL_DISCLAIMER}
          </P>
        </Col>
      </Row>
    </Container>
  );
};

export { MenuDisclaimer };
