import { useCallback } from 'react';
import { CONTEXT_TYPES, ORDER_TYPES } from '@nandosaus/constants';
import { has, isNaN, isNil, parseInt, split, toUpper } from 'lodash';
import { useRouter } from 'next/router';
import { useRootStore } from '@nandosaus/state-management';

const validateNumberQueryParam = queryParam => !isNil(queryParam) && !isNaN(parseInt(queryParam));

const getQueryParams = (query = {}) => {
  let queryRestaurant = query.r;
  let queryOrderType = query.ot;
  const queryTableNumber = query.t;

  const [jointRestaurant, jointOrderType] = split(query.r || '', 'ot=');
  if (jointRestaurant && jointOrderType) {
    queryRestaurant = jointRestaurant;
    queryOrderType = jointOrderType;
  }

  queryOrderType = toUpper(queryOrderType);

  const isValidRestaurant = validateNumberQueryParam(queryRestaurant);
  const isValidOrderType = !isNil(queryOrderType) && has(ORDER_TYPES, queryOrderType);
  const isValidTableNumber = validateNumberQueryParam(queryTableNumber);

  const showAllOrderTypes = !isNil(query.showAllOrderTypes) && query.showAllOrderTypes === 'true';

  return {
    restaurant: isValidRestaurant ? queryRestaurant : undefined,
    orderType: isValidOrderType ? queryOrderType : undefined,
    tableNumber: isValidTableNumber ? queryTableNumber : undefined,
    showAllOrderTypes,
  };
};

export const useDineInPreSet = () => {
  const router = useRouter();
  const { AlertsStore, OrderingContextStore, RestaurantStore } = useRootStore();

  const params = getQueryParams(router?.query);

  const isRestaurantPreSet = params.restaurant !== undefined;

  const applyPreSet = useCallback(async () => {
    const applyDineInPrefillOptions = () => {
      const restaurant = RestaurantStore.getRestaurantById(params.restaurant);
      const { tableNumber } = params;

      OrderingContextStore.switchContext(CONTEXT_TYPES.PRESET, {
        orderType: params.orderType,
        restaurantId: restaurant?.id,
        tableNumber,
        showAllOrderTypes: params.showAllOrderTypes,
      }).whenFail((_, result) => AlertsStore.add(result.toAlert()));

      return {
        hasValidOrderSettings: OrderingContextStore.isValid,
      };
    };

    if (isRestaurantPreSet) {
      await (RestaurantStore.loaded ? null : RestaurantStore.loadRestaurants());

      return applyDineInPrefillOptions();
    }

    // This matches the previous behaviour (commit 1a5efed0a7d6585f4a830270b486ee0cf982cab8), however since we've got more structure
    // we should be able to mantain the preset restrictions for a set time instead of just on the welcome page. The previous flag had a
    // timeout of 1 hour (although in practice it was not ever reached)
    OrderingContextStore.resetContext({ only: CONTEXT_TYPES.PRESET });

    return {
      hasValidOrderSettings: true,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRestaurantPreSet]);

  const hasAllSettings = OrderingContextStore.isValid;

  return { isRestaurantPreSet, hasAllSettings, applyPreSet };
};
