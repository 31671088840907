import { get, isNil, mapValues } from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useRootStore } from '@nandosaus/state-management';
import NextImage from 'next/image';
import PropTypes from 'prop-types';

import { ANALYTICS_EVENTS, colors } from '@nandosaus/constants';
import { useIsMinWidth } from '@hooks/use-is-min-width';
import { useBrazeImpression } from '@hooks/use-braze-impression';
import { HeadingWithLineHighlights } from '../heading-with-line-highlights';
import { analytics } from '../../analytics';
import { Box, Col, Container, Flex, Row } from '../grid';
import { Image as ExternalImage } from '../image';
import { imageTransform } from '../../content/transforms';
import { OrderSettingsSummary } from '../order-settings-summary';
import backgroundImage from '../../../public/static/images/menu/menu_hero.jpg';

const useBrazeOrderSettingsBanner = ({ heading, image, imageComponent, border }) => {
  const { BrazeStore } = useRootStore();

  const { getLatestContentCardExtraByType } = BrazeStore;
  const cardMenuHeroImage = getLatestContentCardExtraByType('menu-hero-image');

  if (!cardMenuHeroImage) {
    return {
      heading,
      border,
      imageComponent,
    };
  }

  const brazeContent = mapValues({ heading, image, border }, (value, key) => get(cardMenuHeroImage, key, null));
  const brazeImage = get(imageTransform(brazeContent.image), 'url', backgroundImage);

  return {
    ...brazeContent,
    brazeLogImpression: cardMenuHeroImage?.brazeLogImpression,
    imageComponent: (
      <ExternalImage
        alt="a Nando’s meal on a table including PERi-PERi chicken, a salad, chips, and a selection of sides"
        src={brazeImage}
        layout="fill"
        priority
        quality={85}
        objectFit="fill"
        height="100%"
        width="100vw"
        position="absolute"
      />
    ),
  };
};

const OrderSettingsBanner = ({ isRestaurantPreSet, isShortHeader, onClick }) => {
  const [shouldShowOrderSettings, setShouldShowOrderSettings] = useState(false);
  const { OrderingContextStore } = useRootStore();

  useEffect(() => {
    if ((isNil(OrderingContextStore.restaurant) && isRestaurantPreSet) || isShortHeader) {
      setShouldShowOrderSettings(false);
    } else {
      setShouldShowOrderSettings(true);
    }
  }, [OrderingContextStore.restaurant, isRestaurantPreSet, isShortHeader]);

  const content = {
    heading: 'Our menu',
    image: backgroundImage,
    imageComponent: (
      <>
        <Box zIndex="1" position="absolute" width="100%" height="100%" opacity="35%" backgroundColor={colors.black} />
        <NextImage
          alt="a Nando’s meal on a table including PERi-PERi chicken, a salad, chips, and a selection of sides"
          src={backgroundImage}
          backgroundColor="rgba(0,0,0, 0.15)"
          layout="fill"
          priority
          quality={85}
          objectFit="cover"
          sizes="100vw"
        />
      </>
    ),
  };

  const { heading, imageComponent, brazeLogImpression } = useBrazeOrderSettingsBanner(content);

  useBrazeImpression({ brazeLogImpression });

  const isMinWidth = !useIsMinWidth('md');

  const headerHeight = useMemo(() => {
    if (isShortHeader) {
      return {
        xs: '80px',
        sm: '260px',
        md: '300px',
        lg: '380px',
      };
    }

    return {
      xs: '200px',
      sm: '280px',
      lg: '440px',
    };
  }, [isShortHeader]);

  return (
    <Flex flexDirection="column" width="100%">
      <Box position="relative" height={headerHeight} width="100%">
        <Box display={isShortHeader && { xs: 'none', sm: 'block' }}>{imageComponent}</Box>

        <Box display="flex" position="relative" alignItems="center" justifyContent="center" height="100%">
          <Container mt={3} mb={2}>
            <Row pt={isShortHeader && 3} mb={!isShortHeader && 3}>
              <Col textAlign="center" width={1}>
                <HeadingWithLineHighlights
                  zIndex={2}
                  heading={heading}
                  headingLevel="h1"
                  highlightBackgroundColor="neonRed"
                  highlightColor="white"
                  overrideHeadingHighlighting={['highlightAllLines']}
                />
              </Col>
            </Row>
            {!isMinWidth && shouldShowOrderSettings && (
              <Row>
                <Col
                  zIndex={2}
                  width={{ xs: 12 / 12, sm: 8 / 12, xl: 6 / 12 }}
                  offset={[0 / 12, 2 / 12, 2 / 12, 2 / 12, 3 / 12]}
                >
                  <OrderSettingsSummary
                    onClick={() => {
                      analytics.track(ANALYTICS_EVENTS.ORDER_SETTINGS_OPENED, {
                        location: 'Banner',
                      });
                      onClick();
                    }}
                    isCompact={isMinWidth}
                  />
                </Col>
              </Row>
            )}
          </Container>
        </Box>
      </Box>
      {isMinWidth && shouldShowOrderSettings && (
        <Box p="1.5rem" bg="white">
          <OrderSettingsSummary
            onClick={() => {
              analytics.track(ANALYTICS_EVENTS.ORDER_SETTINGS_OPENED, {
                location: 'Banner',
              });
              onClick();
            }}
            isCompact={isMinWidth}
          />
        </Box>
      )}
    </Flex>
  );
};

OrderSettingsBanner.propTypes = {
  isRestaurantPreSet: PropTypes.bool,
  isShortHeader: PropTypes.bool,
  onClick: PropTypes.func,
};

OrderSettingsBanner.defaultProps = {
  isRestaurantPreSet: false,
  isShortHeader: false,
  onClick: () => {},
};

const ComposedOrderSettingsBanner = observer(OrderSettingsBanner);
export { ComposedOrderSettingsBanner as OrderSettingsBanner };
