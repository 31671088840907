import { Form, Formik } from 'formik';
import { isEmpty, noop } from 'lodash';
import { PropTypes } from 'prop-types';
import { useCallback, useState } from 'react';
import { useRootStore } from '@nandosaus/state-management';

import { Button } from '../button';
import { Flex } from '../grid';
import { inputSize } from '../../constants';
import { Modal } from '../modal';
import { P } from '../typography';
import { TextInput } from '../input';

const AddOfferModal = ({ open, handleClose, openOfferModal, onOfferApply }) => {
  const [isLoading, setIsLoading] = useState();
  const { OfferStore, CartStore } = useRootStore();

  const onSubmit = useCallback(
    async ({ promoCode }, { setFieldError }) => {
      setIsLoading(true);
      OfferStore.updatePromoCode(promoCode);
      const unlockedOffer = await OfferStore.checkPromoCode();

      if (!unlockedOffer) {
        setFieldError('promoCode', 'Enter a valid promo code');
      } else if (OfferStore.error) {
        setFieldError('promoCode', 'Error occurred adding promo code');
      } else if (!isEmpty(unlockedOffer.plus)) {
        openOfferModal(unlockedOffer);
      } else {
        CartStore.updateOffer(unlockedOffer);
        onOfferApply(unlockedOffer);
        handleClose(true);
      }

      setIsLoading(false);
    },
    [CartStore, OfferStore, handleClose, onOfferApply, openOfferModal]
  );

  return (
    <Modal modalTitle="Add Promo Code" open={open} handleClose={() => handleClose()}>
      <Flex flexDirection="column" p={2} pt={1}>
        <Formik initialValues={{ promoCode: '' }} onSubmit={onSubmit}>
          {({ errors, values, handleChange }) => (
            <Form>
              <Flex flexDirection="row">
                <Flex flexDirection="column" width="100%">
                  <TextInput
                    label="Code"
                    name="promoCode"
                    onChange={value => handleChange('promoCode')(value)}
                    autoFocus
                    maxLength={40}
                  />
                </Flex>

                <Flex alignSelf="flex-end" ml={1} mb="2px">
                  <Button
                    height={{ _: inputSize.sm, md: inputSize.md, lg: inputSize.lg }}
                    type="submit"
                    isLoading={isLoading}
                    disabled={isLoading || !values.promoCode}
                  >
                    ADD CODE
                  </Button>
                </Flex>
              </Flex>
              <P variant={3} color="errorPrimary" mt="0.5rem">
                {errors.promoCode}
              </P>
            </Form>
          )}
        </Formik>
      </Flex>
    </Modal>
  );
};

AddOfferModal.propTypes = {
  open: PropTypes.bool,
  onOfferApply: PropTypes.func,
  openOfferModal: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
};

AddOfferModal.defaultProps = {
  open: false,
  onOfferApply: noop,
  openOfferModal: noop,
};

export { AddOfferModal };
