import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { ButtonElement } from '@components/button/button-element';
import { useBrazeImpression } from '@hooks/use-braze-impression';
import { Box, Flex } from '../../grid';
import { Image } from '../../image';
import { H2, P } from '../../typography';
import { TextClamp } from '../../text-clamp';
import { breakpoints } from '../../../constants';

const CardHeading = styled(H2)`
  font-size: 36px;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 33px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 45px;
  }
`;

const PROMO_CARD_NUMBER_HEIGHT_BREAKPOINTS = {
  _: 200,
  md: 230,
  lg: 276,
};
export const PROMO_CARD_HEIGHT_BREAKPOINTS = {
  _: `${PROMO_CARD_NUMBER_HEIGHT_BREAKPOINTS._}px`,
  md: `${PROMO_CARD_NUMBER_HEIGHT_BREAKPOINTS.md}px`,
  lg: `${PROMO_CARD_NUMBER_HEIGHT_BREAKPOINTS.lg}px`,
};
const ASPECT_RATIO = 300 / 184;
const PROMO_CARD_WIDTH_BREAKPOINTS = {
  _: `${PROMO_CARD_NUMBER_HEIGHT_BREAKPOINTS._ * ASPECT_RATIO}px`,
  md: `${PROMO_CARD_NUMBER_HEIGHT_BREAKPOINTS.md * ASPECT_RATIO}px`,
  lg: `${PROMO_CARD_NUMBER_HEIGHT_BREAKPOINTS.lg * ASPECT_RATIO}px`,
};

const backgroundChicken = '/static/images/welcome-page/promocards_textured_chicken_lg.png';
const backgroundChips = '/static/images/welcome-page/promocards_textured_chips_lg.png';

const BrazePromotionCard = ({ brazeData, aspectRatio, height, width }) => {
  useBrazeImpression({ brazeLogImpression: brazeData?.brazeLogImpression });

  const { header, description, disclaimer, buttonText, buttonLink, image, textWidth } = brazeData;

  return (
    <PromotionCard
      header={header}
      description={description}
      disclaimer={disclaimer}
      buttonText={buttonText}
      buttonLink={buttonLink}
      textWidth={textWidth}
      image={image?.[0]?.url || backgroundChicken}
      onClick={() => {
        brazeData.brazeLogClick?.();
      }}
      aspectRatio={aspectRatio}
      height={height}
      width={width}
    />
  );
};

BrazePromotionCard.propTypes = {
  brazeData: PropTypes.object.isRequired,
  aspectRatio: PropTypes.string,
  height: StyledSystemPropTypes.layout.height,
  width: StyledSystemPropTypes.layout.width,
};

BrazePromotionCard.defaultProps = {
  aspectRatio: null,
  height: PROMO_CARD_HEIGHT_BREAKPOINTS,
  width: PROMO_CARD_WIDTH_BREAKPOINTS,
};

const OfferPromotionCard = ({ offer, isApplied, onClick, aspectRatio, height, width, hideButton }) => (
  <PromotionCard
    header={offer?.name}
    description={offer?.description}
    disclaimer={offer?.formattedExpiryDate && `Expires ${offer.formattedExpiryDate}`}
    buttonText={isApplied ? 'APPLIED' : offer?.formattedLabel}
    buttonLink={null}
    onClick={onClick}
    image={offer?.image || backgroundChips}
    aspectRatio={aspectRatio}
    height={height}
    width={width}
    hideButton={hideButton}
  />
);

OfferPromotionCard.propTypes = {
  offer: PropTypes.object.isRequired,
  isApplied: PropTypes.bool,
  onClick: PropTypes.func,
  aspectRatio: PropTypes.string,
  height: StyledSystemPropTypes.layout.height,
  width: StyledSystemPropTypes.layout.width,
  hideButton: PropTypes.bool,
};

OfferPromotionCard.defaultProps = {
  isApplied: false,
  onClick: null,
  aspectRatio: null,
  height: PROMO_CARD_HEIGHT_BREAKPOINTS,
  width: PROMO_CARD_WIDTH_BREAKPOINTS,
  hideButton: false,
};

const PromotionCard = ({
  header,
  description,
  disclaimer,
  image,
  buttonText,
  buttonLink,
  onClick,
  role,
  aspectRatio,
  height,
  width,
  textColor,
  textWidth,
  hideButton,
}) => {
  return (
    <Box
      role={role}
      flexShrink={0}
      style={{ aspectRatio }}
      height={height}
      width={width}
      position="relative"
      borderRadius="8px"
      overflow="hidden"
    >
      <ButtonElement style={{ display: 'block', width: '100%', height: '100%' }} onClick={onClick} href={buttonLink}>
        <Flex
          position="absolute"
          flexDirection="column"
          maxWidth={textWidth}
          alignItems="flex-start"
          justifyContent="space-between"
          pl="1rem"
          py="0.5rem"
          height="100%"
          gap="4px"
          textAlign="left"
        >
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            zIndex={2}
            height="100%"
            maxWidth="100%"
            flex={1}
            color={textColor}
          >
            <CardHeading hideTramlines color="inherit" zIndex={2}>
              {header}
            </CardHeading>

            <P variant={2} color="inherit">
              <TextClamp lines={3}>{description}</TextClamp>
            </P>

            {disclaimer && (
              <P variant={4} color="inherit">
                {disclaimer}
              </P>
            )}
            {(!!onClick || buttonText) && !hideButton ? (
              <Box
                color="white"
                backgroundColor="black"
                data-testid="offer-card-cta"
                selfAlign="left"
                p="5px 8px"
                mt={1}
                borderRadius="4px"
              >
                <P variant={2} color="white" textTransform="none">
                  {capitalize(buttonText)}
                </P>
              </Box>
            ) : null}
          </Flex>
        </Flex>

        <Image width="100%" height="100%" src={image} objectFit="cover" />
      </ButtonElement>
    </Box>
  );
};

PromotionCard.propTypes = {
  onClick: PropTypes.func,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  image: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  role: PropTypes.string,
  aspectRatio: PropTypes.string,
  height: StyledSystemPropTypes.layout.height,
  width: StyledSystemPropTypes.layout.width,
  textColor: PropTypes.string,
  /**
    The card size is set quite explicitly, always adhering to the same aspect ratio
    This is to make it easier for Nando's to enter a background image, and predict how the card content will behave with it
  */
  textWidth: PropTypes.string,
  hideButton: PropTypes.bool,
};

PromotionCard.defaultProps = {
  onClick: null,
  disclaimer: null,
  buttonText: null,
  buttonLink: null,
  role: null,
  aspectRatio: null,
  height: PROMO_CARD_HEIGHT_BREAKPOINTS,
  width: PROMO_CARD_WIDTH_BREAKPOINTS,
  textColor: 'white',
  textWidth: '50%',
  hideButton: false,
};

export { PromotionCard, BrazePromotionCard, OfferPromotionCard };
