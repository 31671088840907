import PropTypes from 'prop-types';
import { Modal } from '@components/modal';
import { Button } from '@components/button';
import styled from 'styled-components';
import { ModalFloatingHeader } from '@components/modal/modal-header';
import { analytics } from '@analytics';
import { Box, Flex } from '@components/grid';
import { ANALYTICS_EVENTS, RECOMMENDATION_ENGINE, colors } from '@nandosaus/constants';
import { P, Subheading } from '@components/typography';
import { TextClamp } from '@components/text-clamp';
import { useRootStore } from '@nandosaus/state-management';
import { GeneralAllergenPDFLink } from '@components/general-allergen-pdf-link';
import { ErrorAlert, HeaderContent } from '../ui';

const ReviewScreen = ({ modalOpen, handleModalClose, setVisibleCart, setFlowStep }) => {
  const { CartStore, RecommendationEngineStore } = useRootStore();
  const { orderItems } = RecommendationEngineStore;

  const onModalClose = () => {
    if (!RecommendationEngineStore.addedRecommendationsToCart) {
      const { recommendationsInput } = RecommendationEngineStore;
      analytics.track(ANALYTICS_EVENTS.RECOMMENDED_CART_ABANDONED, {
        cartId: CartStore.cartId,
        restaurantId: recommendationsInput.restaurantId,
        mealType: recommendationsInput.mealType,
        basting: recommendationsInput.basting,
        groupKidsNumber: recommendationsInput.group.kids,
        groupAdultsNumber: recommendationsInput.group.adults,
        subtotalPriceInCents: RecommendationEngineStore.subtotalPrice.cents,
      });
    }
    handleModalClose();
  };

  const acceptRecommendations = () => {
    RecommendationEngineStore.addRecommendationsToCart();
  };

  return (
    <Modal
      open={modalOpen}
      handleClose={onModalClose}
      isDividerHidden
      backgroundImage="/static/images/background.svg"
      headerBackgroundColor="transparent"
      header={
        <ModalFloatingHeader
          handleClose={onModalClose}
          handleBack={() => setFlowStep(RECOMMENDATION_ENGINE.screens.quiz)}
        />
      }
      footer={
        <>
          {RecommendationEngineStore.error && <ErrorAlert />}
          <Flex width="100%" gap="0.5rem" justifyContent="center">
            <Button
              onClick={() => {
                acceptRecommendations();
                handleModalClose();
              }}
              isFullWidth
              variant="secondary"
            >
              Add items & browse
            </Button>
            <Button
              onClick={() => {
                acceptRecommendations();
                setVisibleCart(true);
                handleModalClose();
              }}
              isFullWidth
            >
              Add items & view cart
            </Button>
          </Flex>
        </>
      }
    >
      <HeaderContent
        heading="Take your pick"
        subheading="There’s no wrong choice here. And, if you change your mind, you can make edits later."
      />
      <Box px={2} py={1} pb={{ _: 4, md: 0 }}>
        {orderItems.map(orderItem => {
          const dietOptions = orderItem.product.formattedSuitability || [];
          return (
            <Grid py={1} key={orderItem.id}>
              <Image src={orderItem.product.image} alt={orderItem.product.description} />
              <QuantityBox
                aria-label="quantity"
                ml={1}
                width={{ _: '25px', lg: '30px' }}
                height={{ _: '25px', lg: '30px' }}
              >
                <P>{orderItem.quantity}</P>
              </QuantityBox>
              <ContentContainer
                flexDirection="column"
                pl={1}
                height="auto"
                alignSelf="stretch"
                justifyContent="space-between"
              >
                <Box>
                  <Subheading variant={3}>{orderItem.product.name}</Subheading>
                  <P mt="0.5rem" variant={3} color="greyPrimary">
                    <TextClamp lines={2}>{orderItem.product.description}</TextClamp>
                  </P>
                  {orderItem.product.formattedKilojoules && (
                    <P mt="0.5rem" variant={3} color="greyPrimary">
                      <TextClamp>{orderItem.product.formattedKilojoules} per serve</TextClamp>
                    </P>
                  )}
                </Box>
                {dietOptions && dietOptions.length > 0 && (
                  <P mt={1} variant={3}>
                    <span style={{ fontWeight: 'bold' }}>{dietOptions.join(' ')}</span> Options
                  </P>
                )}
              </ContentContainer>
            </Grid>
          );
        })}
        <Box my={1}>
          <GeneralAllergenPDFLink centeredText />
        </Box>
        <Box display="grid" rowGap="0.5rem" py={1}>
          <Flex justifyContent="space-between" pb={1}>
            <P variant={1}>Sub total</P>
            <P variant={1}>{RecommendationEngineStore.subtotalPrice.formattedPrice}</P>
          </Flex>
          <Subheading variant={3} textAlign="center">
            The average adult daily energy intake is 8,700kJ.
          </Subheading>
          <P variant={3} color="greyPrimary" textAlign="center" pt={1} pb={2}>
            You can change your items, specify dietary requirements and add extras later from your basket.
          </P>
        </Box>
      </Box>
    </Modal>
  );
};

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 40px 2.75fr;
`;

const Image = styled.img`
  display: inline;
  grid-column-start: 1;
  grid-column-end: 2;
  width: 100%;
  min-width: 100px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 4px;
  overflow: hidden;
`;

const QuantityBox = styled(Flex)`
  justify-self: end;
  grid-column-start: 2;
  grid-column-end: 3;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid ${colors.grey300};
`;

const ContentContainer = styled(Flex)`
  grid-column-start: 3;
  grid-column-end: 4;
`;

ReviewScreen.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  setFlowStep: PropTypes.func.isRequired,
  setVisibleCart: PropTypes.func.isRequired,
};

export { ReviewScreen };
