import { P } from '@components/typography';
import { BASTINGS, BASTING_TYPES, MARKET } from '@nandosaus/constants';
import { BastingSelector } from '@components/basting-selector';
import { useField } from 'formik';
import { forwardRef } from 'react';
import { useMarket } from '@hooks/use-market';
import { Flex } from '@components/grid';
import { Section, ErrorMessage, InactiveError, GreenHeading } from '../../ui';

export const SelectBasting = forwardRef((props, ref) => {
  const market = useMarket();
  const validateBasting = basting => (basting ? undefined : 'Required');
  const [field, meta, helper] = useField({ name: 'basting', validate: validateBasting });
  const { setValue, setTouched } = helper;
  const { value } = field;
  const { error, touched } = meta;
  const bastingOptionsList = Object.values(BASTING_TYPES).map(bastingValue => {
    if (bastingValue === BASTING_TYPES.PLAIN) {
      return {
        value: bastingValue,
        label: BASTINGS[bastingValue].name[market === MARKET.AUSTRALIA ? 'au' : 'nz'],
      };
    }
    return {
      value: bastingValue,
      label: BASTINGS[bastingValue].name,
    };
  });

  return (
    <Section ref={ref}>
      <Flex>
        <GreenHeading>Pick your heat</GreenHeading>
      </Flex>
      <P>This will apply to all items. But don’t worry, you can change it later.</P>
      {error && touched ? <ErrorMessage>{error}</ErrorMessage> : <InactiveError>Required</InactiveError>}
      <BastingSelector
        bastingOptions={bastingOptionsList}
        onChange={basting => {
          setTouched(true, false);
          setValue(basting);
        }}
        selectedOptions={[value]}
      />
    </Section>
  );
});
