import { useEffect, useState } from 'react';

// Detect client side render and avoid hydration mismatch error
// @note: implementation from: https://usehooks-ts.com/react-hook/use-is-client

export const useIsClient = () => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return isClient;
};
