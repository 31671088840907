import { DELIVERY_DISCOUNT_SPONSORS } from '@nandosaus/constants';
import PropTypes from 'prop-types';

import { Image } from '../image';
import { logger } from '../../utils/logger';

const deliverySponsorLogoMap = {
  [DELIVERY_DISCOUNT_SPONSORS.COKE]: (
    <Image alt="Coca-Cola logo" src="/static/images/brand/coca-cola-logo.png" width={82} height={28} />
  ),
};

const DeliverySponsorLogo = ({ deliverySponsor }) => {
  const SponsorLogo = deliverySponsorLogoMap[deliverySponsor?.handle];

  if (!SponsorLogo) {
    logger.warn(`'${deliverySponsor.name}' logo not supported`);
    return null;
  }

  return SponsorLogo;
};

DeliverySponsorLogo.propTypes = {
  deliverySponsor: PropTypes.shape({
    handle: PropTypes.string,
    name: PropTypes.string,
  }),
};

DeliverySponsorLogo.defaultProps = {
  deliverySponsor: null,
};

export { DeliverySponsorLogo };
