import PropTypes from 'prop-types';
import React from 'react';

import { Alert } from '../alert';
import { Col, Container, Row } from '../grid';

const heading = {
  menu: 'Well, this is awkward!',
  restaurant: 'Don’t worry, we’ll be back soon.',
};

const StockShortageAlert = ({ page }) => {
  return (
    <Container>
      <Row my={{ _: 1, lg: 2 }}>
        <Col width={{ _: 12 / 12, lg: 10 / 12 }} offset={[0, 0, 0, 1 / 12]}>
          <Alert allowDismissal heading={heading[page]} type="info">
            We’re temporarily seeing some not-so-cheeky disruption to our supply chain. That means some of your fave
            menu items aren’t available and some restaurants may not be open as often as we’d like. We’re super sorry
            for any hassle (or cravings) this might cause. We’re doing everything we can to get PERi-PERi back where it
            belongs – on your plate!
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

StockShortageAlert.propTypes = {
  page: PropTypes.oneOf(['menu', 'restaurant']).isRequired,
};

export { StockShortageAlert };
