import { useRootStore } from '@nandosaus/state-management';
import { useEffect, useState } from 'react';

export const useCartVisibility = (defaultValue = false) => {
  const { CartStore } = useRootStore();
  const [isCartVisible, setIsCartVisible] = useState(defaultValue);

  useEffect(() => {
    if (!isCartVisible) {
      CartStore.clearRemovedSubCarts();
    }
  }, [CartStore, isCartVisible]);

  return [isCartVisible, setIsCartVisible];
};
