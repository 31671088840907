import PropTypes from 'prop-types';
import ReactBarcode from 'react-barcode';

const Barcode = ({ value, width }) => <ReactBarcode value={value} displayValue={false} width={width} />;

Barcode.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number,
};

Barcode.defaultProps = {
  width: 2,
};

export { Barcode };
