import { ALERT_TYPES, PRODUCT_EXISTS_IN_CART_KEY } from '@nandosaus/constants';
import { concat, filter, find, get, head, isEmpty, isNil } from 'lodash';
import { PropTypes } from 'prop-types';
import { useRootStore } from '@nandosaus/state-management';
import { useRouter } from 'next/router';
import React, { useEffect, useReducer, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { Alert } from '../../alert';
import { Barcode } from '../../barcode';
import { Box, Flex } from '../../grid';
import { Button } from '../../button';
import { Modal } from '../../modal';
import { navigation } from '../../../constants';
import { offerPropTypes } from '../../../prop-types';
import { P, Subheading } from '../../typography';
import { ProductDetailModal } from '../../product-detail-modal';
import { StylePicker } from '../../style-picker';
import { OfferPromotionCard } from '../promotion-card';

const REDUCER_EVENTS = {
  USE_ITEMS_IN_CART: 'useItemsInCart',
  SELECT_OFFER_PRODUCT: 'selectProduct',
  SELECT_PRODUCT_FOR_OFFER_EXTRA: 'selectProductForExtra',
  SELECT_OFFER_EXTRA: 'selectExtra',
};

const reducer = (state, action) => {
  switch (action.type) {
    case REDUCER_EVENTS.USE_ITEMS_IN_CART:
      return { selectedOfferProductPLU: null, isUsingExistingItemsInCart: true };
    case REDUCER_EVENTS.SELECT_OFFER_PRODUCT:
      return { selectedOfferProductPLU: action.plu, isUsingExistingItemsInCart: false };
    case REDUCER_EVENTS.SELECT_PRODUCT_FOR_OFFER_EXTRA:
      return { selectedProductForOfferExtraID: action.id, isUsingExistingItemsInCart: false };
    case REDUCER_EVENTS.SELECT_OFFER_EXTRA:
      return {
        selectedProductForOfferExtraID: state.selectedProductForOfferExtraID,
        selectedOfferExtraPLU: action.plu,
        isUsingExistingItemsInCart: false,
      };
    default:
      throw new Error(`${action.type} is not valid`);
  }
};

const initialState = {
  selectedOfferProductPLU: null,
  selectedProductForOfferExtraID: null,
  selectedOfferExtraPLU: null,
  isUsingExistingItemsInCart: false,
};

const OfferModal = ({ modalOpen, isApplied, handleModalClose: closeOfferModal, offer, onOfferChange, handleBack }) => {
  const { CartStore, ProductDetailsState } = useRootStore();
  const router = useRouter();

  const [productDetailModalOpen, setProductDetailModalOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    selectedOfferProductPLU,
    isUsingExistingItemsInCart,
    selectedProductForOfferExtraID,
    selectedOfferExtraPLU,
  } = state;
  const {
    isAvailableInRestaurant,
    isAvailableOnline,
    availableProductOfferChoicesLabels,
    productOfferChoices,
    availableExtraOfferProductChoicesLabels,
    isForExtras,
    extrasInMenuApplicableForOffer,
    getAvailableExtraOfferExtraChoicesLabels,

    // opening product detail modal from offer modal for meal products is broken, doesn't let you add item to cart
    // so, if offer has any meal products, we disallow adding to cart from offer modal
    isMealProductOffer,
  } = offer;

  const displayBarcode = isAvailableInRestaurant;
  const offerHasPLUsButNoProductsFound =
    isEmpty(productOfferChoices) && isEmpty(extrasInMenuApplicableForOffer) && !isEmpty(offer.plus);

  const productsForExtraOffer = availableExtraOfferProductChoicesLabels;
  const extrasForExtraOffer = getAvailableExtraOfferExtraChoicesLabels(selectedProductForOfferExtraID);
  const showBrowseMenuButton = isForExtras && isEmpty(productsForExtraOffer);

  const handleOfferChange = () => {
    onOfferChange();

    if (!isApplied && router.pathname !== navigation.MENU) {
      router.push({ pathname: navigation.MENU, query: 'showCart' });
    }

    closeOfferModal();
  };

  useEffect(() => {
    const noOfferChoicesAvailable =
      (isForExtras && isEmpty(productsForExtraOffer)) || (!isForExtras && isEmpty(productOfferChoices));
    const offerChoiceAlreadyMade = !isNil(selectedOfferProductPLU) || !isNil(selectedProductForOfferExtraID);
    if (noOfferChoicesAvailable || offerChoiceAlreadyMade || isUsingExistingItemsInCart) {
      return;
    }

    if (offer.cartIsValidForOffer) {
      dispatch({ type: REDUCER_EVENTS.USE_ITEMS_IN_CART });
    } else if (!isEmpty(offer.plus)) {
      if (isForExtras) {
        const firstOrderItemInList = productsForExtraOffer[0].value;
        dispatch({ type: REDUCER_EVENTS.SELECT_PRODUCT_FOR_OFFER_EXTRA, id: firstOrderItemInList });
      } else {
        const availableProducts = filter(productOfferChoices, 'isAvailable');
        const firstProductInList = head(availableProducts)?.id;
        if (firstProductInList) {
          dispatch({ type: REDUCER_EVENTS.SELECT_OFFER_PRODUCT, plu: firstProductInList });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer.cartIsValidForOffer, offer.plus, productOfferChoices, productsForExtraOffer]);

  const buttonCopy = (() => {
    if (isApplied) {
      return 'Remove offer from order';
    }

    if (
      isUsingExistingItemsInCart ||
      (isNil(selectedOfferProductPLU) && isNil(selectedOfferExtraPLU)) ||
      isMealProductOffer
    ) {
      return 'Apply to order';
    }

    return 'Apply and add to order';
  })();

  const button = isAvailableOnline
    ? {
        primary: (
          <Button
            disabled={!isApplied && isForExtras && !selectedOfferExtraPLU && !isUsingExistingItemsInCart}
            variant="actionPrimary"
            onClick={() => {
              if (isApplied) {
                onOfferChange();
                closeOfferModal();
                return;
              }

              const selectedProduct = find(productOfferChoices, { id: selectedOfferProductPLU });
              const choicesRequired = !isEmpty(get(selectedProduct, 'choices'));
              if (isMealProductOffer) {
                handleOfferChange();
                return;
              }

              if (choicesRequired) {
                ProductDetailsState.viewProduct({ product: selectedProduct });
                setProductDetailModalOpen(true);
                return;
              }

              if (!isUsingExistingItemsInCart && selectedProduct) {
                CartStore.addOrderItem({ product: selectedProduct });
              }

              if (!isUsingExistingItemsInCart && selectedProductForOfferExtraID) {
                const cartOrderItem = find(
                  CartStore.orderItems,
                  orderItem => orderItem.id === selectedProductForOfferExtraID
                );

                const { product } = cartOrderItem;
                const productExtraChoice = product.extraChoice;
                const productExtraChoiceName = get(productExtraChoice, 'name');
                const existingExtrasForCartItem = cartOrderItem.choices.get(productExtraChoiceName) || [];
                CartStore.updateChoice(
                  cartOrderItem,
                  productExtraChoiceName,
                  concat(existingExtrasForCartItem, find(productExtraChoice.options, { plu: selectedOfferExtraPLU }))
                );
              }

              handleOfferChange();
            }}
          >
            {buttonCopy}
          </Button>
        ),
      }
    : undefined;

  const barcodeWidth = useMediaQuery({ query: '(max-width: 390px)' }) ? 1 : 2;

  return (
    <Modal
      open={modalOpen}
      showShadow
      modalTitle={offer.name}
      handleClose={closeOfferModal}
      buttons={button}
      handleBack={handleBack}
    >
      <Flex flexDirection="column">
        <Box mt="1rem" alignSelf="center">
          <OfferPromotionCard offer={offer} isApplied={isApplied} hideButton />
        </Box>

        <Flex flexDirection="column" alignItems="center" p={3} pb={0}>
          {displayBarcode && (
            <>
              <Subheading variant={2} textAlign="center">
                Scan in restaurant to redeem
              </Subheading>
              <Barcode value={offer.barcode} width={barcodeWidth} />
            </>
          )}
        </Flex>

        {!isApplied && (
          <>
            {offerHasPLUsButNoProductsFound && isAvailableOnline && (
              <Flex flexDirection="column" p={3}>
                <Subheading variant={3} pb={1}>
                  Offer Choices
                </Subheading>
                <Alert type={ALERT_TYPES.WARNING}>No choices available at your current restaurant</Alert>
              </Flex>
            )}

            {!isEmpty(availableProductOfferChoicesLabels) && !isForExtras && !isMealProductOffer && (
              <StylePicker
                title="Offer choices"
                type="radio"
                required
                items={availableProductOfferChoicesLabels}
                selectedItems={[isUsingExistingItemsInCart ? PRODUCT_EXISTS_IN_CART_KEY : selectedOfferProductPLU]}
                onSelect={selectedValues => {
                  const plu = selectedValues[0];

                  if (plu.value === PRODUCT_EXISTS_IN_CART_KEY) {
                    dispatch({ type: REDUCER_EVENTS.USE_ITEMS_IN_CART });
                    return;
                  }

                  dispatch({ type: REDUCER_EVENTS.SELECT_OFFER_PRODUCT, plu: selectedValues[0].value });
                }}
              />
            )}
            {!isEmpty(productsForExtraOffer) && (
              <>
                <StylePicker
                  title="Apply extra to"
                  type="radio"
                  required
                  items={productsForExtraOffer}
                  selectedItems={[
                    isUsingExistingItemsInCart ? PRODUCT_EXISTS_IN_CART_KEY : selectedProductForOfferExtraID,
                  ]}
                  onSelect={selectedValues => {
                    const selectedValue = selectedValues[0];

                    if (selectedValue.value === PRODUCT_EXISTS_IN_CART_KEY) {
                      dispatch({ type: REDUCER_EVENTS.USE_ITEMS_IN_CART });
                      return;
                    }

                    dispatch({ type: REDUCER_EVENTS.SELECT_PRODUCT_FOR_OFFER_EXTRA, id: selectedValue.value });
                  }}
                />
                {!isEmpty(extrasForExtraOffer) && (
                  <StylePicker
                    title="Choose an extra"
                    type="radio"
                    required
                    items={extrasForExtraOffer}
                    selectedItems={[selectedOfferExtraPLU]}
                    onSelect={selectedValues => {
                      dispatch({ type: REDUCER_EVENTS.SELECT_OFFER_EXTRA, plu: selectedValues[0].value });
                    }}
                  />
                )}
              </>
            )}
            {showBrowseMenuButton && (
              <Flex mx={1} flexDirection="column">
                <P my={1} variant={2}>
                  Extras only apply to a select range of products within the Nando’s menu. Please browse our menu and
                  add a burger, wrap, pita, salad or paella to apply this promo.
                </P>
                <Button isFullWidth href={navigation.MENU} onClick={() => closeOfferModal()} mb="1.5rem">
                  Browse Menu
                </Button>
              </Flex>
            )}
          </>
        )}

        <Flex flexDirection="column" alignItems="center" p={2} pt={0}>
          <P variant={2}>{offer.longDescription}</P>

          {productDetailModalOpen && (
            <ProductDetailModal
              showMealCta={false}
              modalOpen={productDetailModalOpen}
              handleModalClose={() => setProductDetailModalOpen(false)}
              handleModalSubmit={() => {
                setProductDetailModalOpen(false);
                handleOfferChange();
              }}
            />
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

OfferModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  isApplied: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  offer: offerPropTypes.isRequired,
  onOfferChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func,
};

OfferModal.defaultProps = {
  handleBack: null,
};

export { OfferModal };
