import PropTypes from 'prop-types';
import { Modal } from '@components/modal';
import { Box } from '@components/grid';
import { Button } from '@components/button';
import { Formik, Form, setNestedObjectValues } from 'formik';
import { ModalFloatingHeader } from '@components/modal/modal-header';
import { useRootStore } from '@nandosaus/state-management';
import { useRef, useState } from 'react';
import { RECOMMENDATION_ENGINE } from '@nandosaus/constants';
import { SelectGroup } from './select-group';
import { SelectMealType } from './select-meal-type';
import { SelectBasting } from './select-basting';
import { ErrorAlert, HeaderContent } from '../ui';

const QuizScreen = ({ modalOpen, handleModalClose, setFlowStep }) => {
  const { RecommendationEngineStore } = useRootStore();
  const [isLoading, setIsLoading] = useState(false);
  const { submitted, getRecommendations, recommendationsInput } = RecommendationEngineStore;
  const inputRefs = useRef({
    group: null,
    mealType: null,
    basting: null,
  });

  const onSubmit = async formInput => {
    setIsLoading(true);
    await getRecommendations(formInput);
    setIsLoading(false);
    setFlowStep(RECOMMENDATION_ENGINE.screens.review);
  };

  const scrollToFirstError = formikErrors => {
    if (Object.keys(formikErrors).length === 0) {
      return;
    }
    const firstErrorKey = Object.keys(formikErrors)[0];
    inputRefs.current[firstErrorKey].scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Formik initialValues={submitted ? recommendationsInput : RECOMMENDATION_ENGINE.initialValues} onSubmit={onSubmit}>
      {({ errors, submitForm, resetForm, setTouched, validateForm, touched }) => {
        const hasTouchedErrors = Object.keys(errors).some(field => touched[field]);

        return (
          <Modal
            open={modalOpen}
            handleClose={handleModalClose}
            isDividerHidden
            backgroundImage="/static/images/background.svg"
            headerBackgroundColor="transparent"
            header={
              <ModalFloatingHeader
                handleClose={() => {
                  resetForm();
                  handleModalClose();
                }}
              />
            }
            footer={
              <>
                {RecommendationEngineStore.error && <ErrorAlert />}
                <Button
                  isLoading={isLoading}
                  loadingText="Prepping your menu"
                  variant={hasTouchedErrors ? 'inactive' : 'actionPrimary'}
                  onClick={async () => {
                    const errs = await validateForm();
                    const errorFields = Object.keys(errs);
                    if (errorFields.length > 0) {
                      setTouched(setNestedObjectValues(errs, true));
                      scrollToFirstError(errs);
                      return;
                    }
                    submitForm();
                  }}
                >
                  See Recommendations
                </Button>
              </>
            }
          >
            <HeaderContent
              heading="We’ve got you"
              subheading="Answer 3 easy questions and we’ll recommend an order for you and your guests."
            />

            <Box px={2} pb={{ _: 5, md: 0 }}>
              <Form>
                <SelectGroup
                  ref={node => {
                    inputRefs.current.group = node;
                  }}
                />
                <SelectMealType
                  ref={node => {
                    inputRefs.current.mealType = node;
                  }}
                />
                <SelectBasting
                  ref={node => {
                    inputRefs.current.basting = node;
                  }}
                />
              </Form>
            </Box>
          </Modal>
        );
      }}
    </Formik>
  );
};

QuizScreen.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  setFlowStep: PropTypes.func.isRequired,
};

export { QuizScreen };
