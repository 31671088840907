import { Alert } from '../alert';
import { Col, Container, Row } from '../grid';

const HalalAlert = () => (
  <Container>
    <Row my={{ _: 1, lg: 2 }} justifyContent="center">
      <Col width={{ _: 12 / 12, lg: 10 / 12 }}>
        <Alert type="info" heading="Halal Certification Update">
          Unfortunately, as of 30th January our QLD supply partners have made changes to their production processes and
          no longer offer Halal chicken.
          <br />
          <br />
          We’re sorry for any inconvenience this causes.
        </Alert>
      </Col>
    </Row>
  </Container>
);

HalalAlert.propTypes = {};

export { HalalAlert };
