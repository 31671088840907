import { Flex } from '@components/grid';
import { H3, P } from '@components/typography';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { colors, RECOMMENDATION_ENGINE } from '@nandosaus/constants';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { useField } from 'formik';
import { forwardRef } from 'react';
import { useIsMinWidth } from '@hooks/use-is-min-width';
import { Section, ErrorMessage, InactiveError, GreenHeading } from '../../ui';

const { mealTypes } = RECOMMENDATION_ENGINE;

export const SelectMealType = forwardRef((props, ref) => {
  const isTablet = useIsMinWidth('md');
  const validateMealType = mealType => (mealType ? undefined : 'Required');
  const [field, meta, helper] = useField({ name: 'mealType', validate: validateMealType });
  const { error, touched } = meta;
  const { setValue, setTouched } = helper;
  const mealType = field.value;
  const description = mealType ? Object.values(mealTypes).find(type => type.value === mealType).description : null;
  return (
    <Section borderColor={colors.grey100} ref={ref}>
      <Flex>
        <GreenHeading>How are y’all feeling?</GreenHeading>
      </Flex>
      <P>Are you keen to indulge or prefer to keep it balanced?</P>
      {error && touched ? <ErrorMessage>{error}</ErrorMessage> : <InactiveError>Required</InactiveError>}
      <ToggleGroupRoot
        type="single"
        aria-label="Diet preference"
        value={mealType}
        onValueChange={value => {
          if (value) {
            setTouched(true, false);
            setValue(value);
          }
        }}
      >
        <ToggleGroupItem value={mealTypes.healthy.value} aria-label={mealTypes.healthy.ariaLabel}>
          <ToggleLabel>{mealTypes.healthy.label}</ToggleLabel>
        </ToggleGroupItem>
        <ToggleGroupItem value={mealTypes.balanced.value} aria-label={mealTypes.balanced.ariaLabel}>
          <ToggleLabel>{mealTypes.balanced.label}</ToggleLabel>
        </ToggleGroupItem>
        <ToggleGroupItem value={mealTypes.indulgent.value} aria-label={mealTypes.indulgent.ariaLabel}>
          <ToggleLabel>{mealTypes.indulgent.label}</ToggleLabel>
        </ToggleGroupItem>
      </ToggleGroupRoot>
      <AnimatePresence>
        {mealType && (
          <motion.div initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: 'auto' }}>
            <Flex alignItems="center" justifyContent="center">
              <StyledDescription>{description}</StyledDescription>
            </Flex>
            <StyledMealTypeImageDiv src={mealTypes[mealType].bannerImageWeb} isTablet={isTablet} />
          </motion.div>
        )}
      </AnimatePresence>
    </Section>
  );
});

const StyledMealTypeImageDiv = styled.div`
  border-radius: 4px;
  aspect-ratio: ${({ isTablet }) => `${isTablet ? 3.32 : 2.25} / 1`};
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-size: cover;
  transition: all 0.3s ease-in-out;
`;

const ToggleGroupRoot = styled(ToggleGroup.Root)`
  border: 1px solid black;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const ToggleGroupItem = styled(ToggleGroup.Item)`
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: black;
  &:not(:last-child) {
    border-right: 1px solid ${colors.grey100};
  }
  &[data-state='on'] {
    color: white;
    background-color: black;
    border-right: 1px solid black;
  }
`;

const ToggleLabel = styled(H3)`
  font-size: 14px;
`;

const StyledDescription = styled(P)`
  text-align: center;
  color: ${colors.grey500};
  margin: 0rem 1rem 1rem 1rem;
  font-style: italic;
`;
