import PropTypes from 'prop-types';
import { useState } from 'react';
import { RECOMMENDATION_ENGINE } from '@nandosaus/constants';
import { ReviewScreen } from './review-screen';
import { QuizScreen } from './quiz-screen';

const RecommendationEngineModal = ({ modalOpen, handleModalClose, setVisibleCart }) => {
  const [flowStep, setFlowStep] = useState(RECOMMENDATION_ENGINE.screens.quiz);

  if (flowStep === RECOMMENDATION_ENGINE.screens.quiz) {
    return <QuizScreen modalOpen={modalOpen} handleModalClose={handleModalClose} setFlowStep={setFlowStep} />;
  }
  if (flowStep === RECOMMENDATION_ENGINE.screens.review) {
    return (
      <ReviewScreen
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        setFlowStep={setFlowStep}
        setVisibleCart={setVisibleCart}
      />
    );
  }
  return null;
};

RecommendationEngineModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  setVisibleCart: PropTypes.func.isRequired,
};

export { RecommendationEngineModal };
