import { H1, Subheading } from '@components/typography';
import styled from 'styled-components';
import { Button } from '@components/button';
import { Box, Flex } from '@components/grid';
import { PropTypes } from 'prop-types';

const CallToAction = ({ title, subtitle, buttonLabel, onClick, backgroundImage }) => {
  return (
    <StyledSection backgroundImage={backgroundImage} data-testid="container">
      <Box bg="rgba(0,0,0,0.4)">
        <Flex px={3} py={1} flexDirection="column" alignItems="center" justifyContent="center">
          <StyledHeading>{title}</StyledHeading>
          {subtitle && (
            <StyledSubheading textAlign="center" color="white">
              {subtitle}
            </StyledSubheading>
          )}
          <Button m={1} variant="quarternary" onClick={onClick}>
            {buttonLabel}
          </Button>
        </Flex>
      </Box>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  position: relative;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 1rem 0rem 1rem 0rem;
  z-index: 0;
  border-radius: 4px;
  overflow: hidden;
`;

const StyledHeading = styled(H1)`
  color: white;
  text-align: center;
`;

const StyledSubheading = styled(Subheading)`
  color: white;
  text-align: center;
`;

CallToAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

CallToAction.defaultProps = {
  subtitle: undefined,
};

export { CallToAction };
