import PropTypes from 'prop-types';
import React from 'react';
import systemPropTypes from '@styled-system/prop-types';

import styled from 'styled-components';
import { Flex } from '../grid';
import { P } from '../typography';

const TextPill = ({ text, backgroundColor, color, height, width, borderRadius, ...props }) => (
  <TiltedFlex
    height={height}
    width={width}
    borderRadius={borderRadius}
    backgroundColor={backgroundColor}
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <P variant={4} color={color} lineHeight={0} fontWeight="bold">
      {text}
    </P>
  </TiltedFlex>
);

const TiltedFlex = styled(Flex)`
  transform: ${({ tiltDegree }) => `rotate(${tiltDegree}deg)`};
`;

TextPill.propTypes = {
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  tiltDegree: PropTypes.number,
  color: PropTypes.string,
  borderRadius: PropTypes.string,
  height: systemPropTypes.layout.height,
  width: systemPropTypes.layout.width,
};

TextPill.defaultProps = {
  text: 'NEW',
  backgroundColor: 'pink600',
  tiltDegree: 0,
  color: 'white',
  borderRadius: '100px',
  height: 20,
  width: 54,
};

export { TextPill };
