import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Flex } from '@components/grid';
import { OrderItemListItem } from '@components/order-item-list-item';
import { ProductDetailModal } from '@components/product-detail-modal';
import { orderItemPropTypes } from '@prop-types/order-item';

const OrderItemList = ({ orderItems, disableOnClick }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Flex flex={1} flexDirection="column">
        {orderItems.map(orderItem => (
          <OrderItemListItem
            orderItem={orderItem}
            disableOnClick={disableOnClick}
            setProductDetailModalOpen={setModalOpen}
          />
        ))}
      </Flex>
      {modalOpen && <ProductDetailModal modalOpen={modalOpen} handleModalClose={() => setModalOpen(false)} />}
    </>
  );
};

OrderItemList.propTypes = {
  orderItems: PropTypes.arrayOf(orderItemPropTypes).isRequired,
  disableOnClick: PropTypes.bool,
};

OrderItemList.defaultProps = {
  disableOnClick: false,
};

const ComposedOrderItemList = observer(OrderItemList);
export { ComposedOrderItemList as OrderItemList };
