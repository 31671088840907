import styled from 'styled-components';
import { H2, H3, P } from '@components/typography';
import { RECOMMENDATION_ENGINE, colors } from '@nandosaus/constants';
import { Box, Flex } from '@components/grid';
import PropTypes from 'prop-types';
import { ImageFadeOverlay } from '@components/image-fade-overlay';
import { headingSize } from '@constants/font-size';
import { breakpoints } from '@constants/breakpoints';
import { navigation } from '@constants/navigation';
import { Alert } from '@components/alert';

const Banner = styled.div`
  aspect-ratio: 1.7 / 1;
  background-image: url(${RECOMMENDATION_ENGINE.bannerWeb});
  background-position: center;
  background-size: cover;
`;

export const HeaderContent = ({ heading, subheading }) => {
  return (
    <>
      <ImageFadeOverlay height="100px">
        <Box position="relative" backgroundColor="black">
          <Banner />
        </Box>
      </ImageFadeOverlay>
      <Box backgroundColor="white" position="relative" height="30px">
        <Flex flexDirection="column" alignItems="flex-start" position="absolute" zIndex={1} px="2rem" top="-35px">
          <Header>{heading}</Header>
        </Flex>
      </Box>

      <Box backgroundColor="white" pt={{ md: '1.5rem' }} pb={1} px={2}>
        <P>{subheading}</P>
      </Box>
    </>
  );
};

HeaderContent.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
};

export const Header = styled(H2)`
  color: white;
  background-color: ${colors.neonRed};
  padding: 0 0.6rem;
  border-radius: 4px;
  line-height: 1.3;
  font-size: ${headingSize.L3M2S1};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${headingSize.L2M1Sx};
  }
`;

export const ErrorMessage = styled(P)`
  color: ${colors.red600};
`;

export const ErrorAlert = () => (
  <Box pb="0.75rem">
    <Alert heading="A critical error has occurred" type="error" href={navigation.HOME}>
      Our team has been notified and is working hard to fix this problem.
    </Alert>
  </Box>
);

export const InactiveError = styled(P)`
  color: ${colors.grey400};
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0 1rem 0;
  border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
`;

export const GreenHeading = styled(H3)`
  color: black;
  background-color: ${colors.success400};
  padding: 0 0.3rem;
  border-radius: 4px;
  line-height: 1.2;
  font-size: ${headingSize.LxM3S2};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${headingSize.L3M2S1};
  }
`;
